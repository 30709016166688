import './App.css'
import './components/temp.css'
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Outlet,
  Router
} from 'react-router-dom'
import { useState, Suspense } from 'react'

import Vrpage from './pages/Vrpage'
import SpinPage from './pages/SpinPage'





function Pages() {
  const locationStart = useLocation()
  const isHome = locationStart.pathname === '/'
  // const isAbout = locationStart.pathname === '/VR'

  return (
    <div>
      <div style={{ display: isHome ? 'block' : 'none' }}>
        <SpinPage />
      </div>

      {/* <div style={{ display: isAbout ? 'block' : 'none' }}>
        <Vrpage />
      </div> */}

   
    </div>
  )
}

function App() {

  return (
    <div className="App">

<Suspense fallback={<div>Loading...</div>}>
      <BrowserRouter>
        <Pages />
           {/* this might be a temp fix to stop the error screen on dom change?? */}

           <Routes>
      <Route path="/VR" element={<Vrpage />} />
      </Routes>
{/* fix end ############################## */}
      </BrowserRouter>
      </Suspense>
    </div>
  )
}

export default App
