import ThreeCanavas from '../components/ThreeCanavas'
import React, { useState } from 'react'

import TouchIcon from '../components/TouchIcon'
import ScreenIdel from '../components/ScreenIdel'

export default function SpinPage() {
  const [isUserIdle, setIsUserIdle] = useState(false)

  const handleIdleChange = (idleState) => {
    setIsUserIdle(idleState)
  }

  const style1 = isUserIdle ? 
  { display: 'block' } : 
  { display: 'none' }

  const style2 = isUserIdle ? 
  { display: 'none' } : 
  { display: 'block' }


  return (
    <div className="Spinpage">
      {/* <ScreenIdel onIdleChange={handleIdleChange} /> */}
      {/* Use isUserIdle for any logic in the Parent */}
        <div>
          {/* <TouchIcon style={style1}/> */}
          <ThreeCanavas style={style2} />
        </div>
    </div>
  )
}
