import { useNavigate, useLocation } from 'react-router-dom'

export default function Vrpage() {
  //works to pass data from anouther component on page change..


  //navigation
  const location = useLocation()
  const data = location.state

  const VRpath = './VR_tour/VRTour.html?s=pano' + data?.vrimg
  const navigate = useNavigate()

  return (
    <div className='Spinpage'>
    <div>
      <iframe className='vr'
        src={VRpath}
        // width="100vw"
        // height="100vh"
        style={{ border: 'none' }}
      />

<div>
      <button className='goback'
        onClick={() => {
          navigate('/')
        }}>
        go back
      </button>
      </div>
    </div>

    </div>
    
  )
}
