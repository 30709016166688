import React, { useState, useEffect } from 'react'
import HtmlMarker from './HtmlMarker'
import { useNavigate } from 'react-router-dom'

export default function MarkerData(Props) {
  const [data, setData] = useState(null)

  //#######################################################
  //move to pano temp
  //pass data to VR component
  const navigate = useNavigate()

  function ClickToVR(VrID) {
    const data = {
      vrimg: VrID
    }
    navigate('/VR', { state: data })
    console.log(data)
  }

  //temp move to pano
  //#######################################################

  useEffect(() => {
    // Fetch data from the JSON file
    fetch('/data/test.json')
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error('Error fetching data:', error))
  }, []) // The empty array means this effect will only run once, similar to componentDidMount()

  if (Props.Plots) {
    return (
      <group>
        {data &&
          data.plots.map((item) => (
            <HtmlMarker
              Lable={item.name}
              position={[item.pos[0], item.pos[2], -item.pos[1]]}
              style={Props.style}
            />
          ))}
      </group>
    )
  } else {
    return (
      <group>
        {data &&
          data.vr.map((item) => (
            <HtmlMarker
              Lable={item.name}
              position={[item.pos[0], item.pos[2], -item.pos[1]]}
              Vr={true}
              Click={()=>{ClickToVR(item.name)}}
              style={Props.style}
            />
          ))}
      </group>
    )
  }
}
