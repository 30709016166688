import { Canvas, useLoader, useThree } from '@react-three/fiber'
import HtmlMarker from './HtmlMarker'
import { TextureLoader, LinearSRGBColorSpace } from 'three'

import GlfLoader from './GlfLoader'

import React, { useState, useEffect, useRef } from 'react'
import { imgLoader, ImagesToObjects, DumpRam } from './Spin/functions'
import { Stats } from '@react-three/drei'

import OrbitCam from './Spin/OrbitCam'
import BGSpinImg from './Spin/BGSpinImg'

import MarkerData from './MarkerData'

export default function ThreeCanavas(Props) {
  const [loadedImages, setLoadedImages] = useState(false)
  const [ImgArr, SetImgArr] = useState()
  const [firstLoad, setfirstLoad] = useState(false)

  const [mouseup, setMouseup] = useState(false)

  const [HRFrameArr, setHRFrameArr] = useState([])

  const SpinFrames = imgLoader('')

  const SpinFramesLR = imgLoader('lr/')

  // console.log(SpinFrames.length)
  const texture = useLoader(TextureLoader, SpinFrames)
  texture.encoding = LinearSRGBColorSpace

  const textureLR = useLoader(TextureLoader, SpinFramesLR)
  texture.encoding = LinearSRGBColorSpace

  const [textture, textturetFrame] = useState()
  const OrbitCamRef = useRef()
  const BGimgObject = useRef()
  const CamRefObj = useRef()

  // var ImgArr

  useEffect(() => {
    const FetchCanvasData = async () => {
      if (SpinFrames.length && texture.length === 180) {
        const ImgArrObjects = ImagesToObjects(texture, textureLR)

        //load to material
        ImgArrObjects.map((item) =>{

          // BGimgObject.current.material.map=item.lrFramePath

          

        })
        
        // console.log("this" +BGimgObject.current)

        SetImgArr(ImgArrObjects)



        textturetFrame(ImgArrObjects[0].framePath)

        setTimeout(() => {
          setLoadedImages(true)
        }, 100) // 2 seconds delay
      }
    }

 

    FetchCanvasData()
  }, [])

 
     
  

 
  function LoadingScreen() {
    return <div className="loader">Loading...</div>
  }

  function DrageControlls(lr) {
    const LRMaps = lr
    // console.log('this is plane:' + BGimgObject)

    if (BGimgObject) {
      BGimgObject.current.lookAt(CamRefObj.current.position)
    }

    //dumping HR frames 
    DumpRam(null, HRFrameArr, setHRFrameArr, true)
    

    const orbitnum = OrbitCamRef.current.getAzimuthalAngle()

    ImgArr.map((item) => {
      const diff = Math.abs(orbitnum - item.id)

      if (firstLoad) {
        if (diff < 0.1) {
          if (LRMaps) {
      
          // DumpRam(item.framePath, HRFrameArr, setHRFrameArr, false)
          // DumpRam(null, HRFrameArr, setHRFrameArr, true)
          textturetFrame(item.lrFramePath)
          DumpRam(item.framePath, HRFrameArr, setHRFrameArr, false)
        
        }
          else {

            

            setTimeout(() => {
              // setLoadedImages(true)

              textturetFrame(item.framePath)
              // textturetFrame(item.lrFramePath)
              DumpRam(item.framePath, HRFrameArr, setHRFrameArr, false)
              DumpRam(null, HRFrameArr, setHRFrameArr, true)
              

              


            }, 10) // 2 seconds delay


           
          }
        }
        return null
      } else {
        textturetFrame(item.framePath)
        DumpRam(item.framePath, HRFrameArr, setHRFrameArr, false)
      }
    })

    setfirstLoad(true)
  }

  function RendererInfo() {
    const { gl } = useThree() // gl represents the WebGLRenderer

    useEffect(() => {
      console.log(gl.info.memory)
    }, [gl])

    return null
  }

  //resizing to browser size..Works keep for testing
  const [windowSize, setWindowSize] = useState({
    width: (window.innerWidth),
    height: (window.innerHeight)
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // const [height, setHeight] = useState('100%'); // Default to 150% viewport h

  // useEffect(() => {
  //   // Logic to compute height, for example:
  //   const computedHeight = window.innerWidth * 1; // Just a sample logic
  //   setHeight(`${computedHeight}px`);
  // }, []);


  return (
    <div className="SpinContainer">
      <Canvas
        style={{
          opacity: !loadedImages ? 0 : 1,
          // height: height
          width: windowSize.width,
          height: windowSize.height
        }}
        shadows
        linear
        flat
        gl={{ antialias: false }}>
          
        {/* <Stats /> */}
        {/* <RendererInfo /> */}
        <OrbitCam
          FrameChange={() => {
            DrageControlls(true)
            // console.log("pointer down")
          }}
          OrbitRef={OrbitCamRef}
          CamRef={CamRefObj}
        
          FrameEnd={() => {
            DrageControlls(false)
          }}
        />

        <BGSpinImg SpinFrame={textture} RefObject={BGimgObject} />

        {/* <GlfLoader MeshPath={'./Geo/ObjHelper.glb'} /> */}

        {/* markers */}
        <MarkerData Plots={true} style={Props.style}/>
        <MarkerData Plots={false} style={Props.style}/>
        {/* markers end */}
      
      </Canvas>

      {/* {!loadedImages && <LoadingScreen />} */}
    </div>
  )
}
