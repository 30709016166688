export default function BGSpinImg(Props) {
  // const colorMap = useLoader(TextureLoader, Props.SpinFrame)

  const colorMap = Props.SpinFrame

  const PlaneScale = 335

  return (
    <mesh position={[86.342, 0, -79.998]} ref={Props.RefObject}>
      <planeGeometry args={[PlaneScale, PlaneScale]} />
      <meshBasicMaterial map={colorMap} color={'white'}  />
      {/* <meshBasicMaterial map={colorMap} color={'white'} opacity={0.5} transparent={true} /> */}
    </mesh>
  )
}
