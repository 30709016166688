import { OrbitControls, PerspectiveCamera } from '@react-three/drei'

const CamAngle = 1.05

export default function OrbitCam(Props) {
  return (
    <group>
      <PerspectiveCamera
        // ref={}
        ref={Props.CamRef}
        makeDefault
        fov={48}
        position={[407.17, 183.35, -79.998]}
        // position={[397.644, 232.945, -80.36]}
      />

      <OrbitControls
        ref={Props.OrbitRef}
        enablePan={false}
        enableZoom={false}
        minPolarAngle={CamAngle}
        maxPolarAngle={CamAngle}
        target={[86.342, 0, -79.998]}
        // minDistance={2}
        // maxDistance={5}
        // maxAzimuthAngle={2.95} //starting angle
        // minAzimuthAngle={2.95}
        // enabled = {false}
        onChange={Props.FrameChange}
        onEnd={Props.FrameEnd}
        // onStart={Props.FrameEnd}
        enableDamping={false}
      />
    </group>
  )
}
