import { Html } from '@react-three/drei'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationPin } from '@fortawesome/free-solid-svg-icons'
// import { faLocationPin } from '@fortawesome/free-regular-svg-icons'
import { faGlasses } from '@fortawesome/free-solid-svg-icons'

export default function HtmlMarker(Props) {
  if (Props.Vr) {
    return (
      <mesh>
        <Html {...Props} style={Props.style}>
          <div className="vr-icon-container" onClick={Props.Click}>
          <div className='marker' style ={{backgroundColor: 'white', borderColor: '145A5E'}}></div>
            {/* <FontAwesomeIcon 
            
              icon={faLocationPin}
              style={{
                color: 'white'
              }}
            /> */}
            
            <FontAwesomeIcon className="icon-overlay" icon={faGlasses} />
          </div>
        </Html>
      </mesh>
    )
  } else {
    return (
      <mesh>
        <Html {...Props} style={Props.style}>
          <div className="vr-icon-container" onClick={Props.Click}>
            
            <div className='marker' style ={{backgroundColor: '#145A5E'
            
          
          }}></div>
            {/* <FontAwesomeIcon
              icon={faLocationPin}
              style={{ color: '#145A5E' }}
            /> */}

            <span  className="vr-overlay-text">
              {Props.Lable}
            </span>
          </div>
        </Html>
      </mesh>
    )
  }
}
