//load images from folder to arr
export function imgLoader(res) {
  let imgArray = []

  const SpinPath = ('./images/' + res)
  const SpinImg = SpinPath + 'Comp 1_'

  for (var i = 0; i < 180; i++) {
    var FrameItem = SpinImg + i + '.jpg'

    imgArray.push(FrameItem)
  }

  return imgArray
}

//convert image array to objects with IDs
export function ImagesToObjects(imgArr, lrImgArr) {
  const imgArray = imgArr
  const LRframes =  lrImgArr

  //was 1.57
  let ItemIDAdd = 1.62
  let NegativeFrames = false
  // const roundNum = Math.round(ItemID * 10) / 10
  const FrameObjects = imgArray.map((item, index) => {
    let ItemID

    const LRversjion = LRframes[index]

    if (ItemIDAdd > 3.1 || ItemIDAdd < 0) {
      if (!NegativeFrames) {
        ItemIDAdd = -ItemIDAdd
        NegativeFrames = true
      } else {
        ItemIDAdd = ItemIDAdd - -0.035
      }

      ItemID = ItemIDAdd
    } else {
      ItemIDAdd = ItemIDAdd + +0.035
      ItemID = ItemIDAdd
    }

    return {
      id: ItemID,
      framePath: item,
      lrFramePath: LRversjion,
     
    }
  })

  return FrameObjects
}



//dump GPU ram
export function DumpRam (arrItem, Arr, SetArr, clear) {



  if (clear) {

      Arr.forEach(texture => {
          texture.dispose();
        });
      
        // Clear the textures array
        SetArr([]);

  }

  else {

      SetArr(prevItems => [...prevItems, arrItem]);
  }

}



